import {
  FormControl,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SEARCH_ICON from "../../assets/icons/search.svg";
import STORE_ICON from "../../assets/icons/store-gray-round.svg";
import { BootstrapInput } from "../../components/CustomInput";
import { ROUTES } from "../../routes/constants";
import {
  VP_ACCORDION_ITEM,
  VP_ACCORDION_ITEM_TEXT,
  VP_ACCORDION_TEXT,
  VP_FILTER_SUBTITLE,
  VP_FILTER_TITLE,
  VP_LEFT_WRAPPER,
  VP_SEARCH_INPUT,
} from "../Region/styles";
import _ from "lodash";

interface StoreFilterProps {
  storesData: any[];
  isLoading: boolean;
}
interface Store {
  storeCode: string;
  numerOfDistricts: number;
  numberOfFeatures: number;
  districts: any[];
}
export default function StoreFilter({
  storesData = [],
  isLoading,
}: StoreFilterProps) {
  const navigate = useNavigate();
  const params = useParams();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedStore, setSelectedStore] = useState<number | null>(null);

  useEffect(() => {
    if (!_.isEmpty(params) && params.storeId) {
      setSelectedStore(parseInt(params.storeId));
    } else {
      setSelectedStore(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setSearchTerm(value);
    // onSearchChange(value);
  };

  const handleStoreSelect = (stCode: number) => {
    setSelectedStore(stCode === selectedStore ? null : stCode);
    navigate(`${ROUTES.DISTRICT_STORE}/${stCode}`, {
      state: { isFromDistrict: true },
    });
  };

  return (
    <Stack sx={VP_LEFT_WRAPPER}>
      <Stack
        padding={"24px 24px 16px 24px"}
        spacing={2}
        sx={{ borderBottom: "1px solid rgba(6, 30, 35, 0.08)" }}
      >
        <Stack spacing={0.5}>
          <Typography sx={VP_FILTER_TITLE}>{"By Stores"}</Typography>
          <Typography sx={VP_FILTER_SUBTITLE}>
            {isLoading ? (
              <Skeleton />
            ) : (
              `Showing ${
                storesData.filter((item) => item.isActive === true).length
              } stores`
            )}
          </Typography>
        </Stack>
        <FormControl variant="outlined">
          <BootstrapInput
            sx={VP_SEARCH_INPUT}
            endAdornment={<img src={SEARCH_ICON} alt="search" />}
            fullWidth
            placeholder="Search for Stores"
            value={searchTerm}
            onChange={onSearch}
          />
        </FormControl>
      </Stack>
      <Stack p={2} spacing={1} overflow={"auto"}>
        {isLoading ? (
          <Stack spacing={1}>
            {[...Array(3)].map((_, index) => (
              <Skeleton
                key={`skeleton-${index}`}
                variant="rounded"
                height={66}
              />
            ))}
          </Stack>
        ) : (
          <List disablePadding>
            {storesData
              ?.filter((store: Store) =>
                searchTerm.length > 1
                  ? store.storeCode.includes(searchTerm)
                  : true,
              )
              .map(
                (store: any, index: number) =>
                  store.isActive && (
                    <ListItemButton
                      key={store.storeId}
                      sx={VP_ACCORDION_ITEM}
                      selected={store.storeId === selectedStore}
                      onClick={() => {
                        handleStoreSelect(store.storeId);
                      }}
                    >
                      <ListItemIcon>
                        <img src={STORE_ICON} alt="person" />
                      </ListItemIcon>
                      <ListItemText disableTypography>
                        {
                          <Stack>
                            <Typography
                              sx={{
                                ...VP_ACCORDION_ITEM_TEXT,
                                color: "#061E23",
                              }}
                            >
                              Store #{store.storeCode}
                            </Typography>
                            <Typography sx={VP_ACCORDION_TEXT}>
                              {store?.storeFeatures?.length || 0} Services
                            </Typography>
                          </Stack>
                        }
                        {/* <ListItemSecondaryAction>
                      <Stack
                        sx={{
                          padding: "8px",
                          background: "rgba(255, 228, 172, 0.30);",
                          borderRadius: "0px 4px",
                        }}
                      >
                        <Typography sx={DM_STORE_LIST_RIGHT_TEXT}>
                          LWK Target: 22
                        </Typography>
                      </Stack>
                    </ListItemSecondaryAction> */}
                      </ListItemText>
                    </ListItemButton>
                  ),
              )}
          </List>
        )}
      </Stack>
    </Stack>
  );
}
