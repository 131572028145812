import { useLocalStorage } from "@uidotdev/usehooks";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import districtService from "../../services/districtService";
import { useLocation } from "react-router-dom";
import { WEEKS_DATA } from "../../utils/constants";

export const useDistrict = () => {
  const [dmStores, setDmStores] = useState([]);
  const [isDistrictLoading, setIsDistrictLoading] = useState(false);
  const [selectedWeek, setSelectedWeek] = useState<string>("");
  const [districtDashboardData, setDistrictDashboardData] = useState<any>(null);
  const [districtEffiencies, setDistrictEffiencies] = useState<any>(null);
  const [districtPlans, setDistrictPlans] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState(0);
  // eslint-disable-next-line
  const [storedWeek, setStoredWeek] = useLocalStorage("storedWeek", "");
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [districtChartData, setDistrictChartData] = useState<any>(null);
  const [keyAlerts, setKeyAlerts] = useState([]);
  const [selectedLastWeek, setSelectedLastWeek] = useState<any>(WEEKS_DATA[0]);

  const { state } = useLocation();
  const isBackFromStore = state ? state.isBackFromStore : false;

  useEffect(() => {
    if (isBackFromStore && selectedWeek) {
      getDistrictDashboard();
      getDistrictEfficiency();
      getDistrictPlans();
      getDistrictChartData();
      getDistrictKeyAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBackFromStore, selectedWeek]);

  useEffect(() => {
    getDistrictManagerStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedWeek) {
      getDistrictDashboard();
      getDistrictEfficiency();
      getDistrictPlans();
      getDistrictChartData();
      getDistrictKeyAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  const getSelectedWeek = (weekRange: any) => {
    if (weekRange) {
      const startWeek = format(weekRange.start, "yyyy-MM-dd");
      setSelectedWeek(startWeek);
      setStoredWeek(JSON.stringify(weekRange));
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const getDistrictManagerStores = async () => {
    try {
      // Fetch logged in user store
      setIsDistrictLoading(true);
      await districtService
        .getDistrictManagerStores("")
        .then(async (response) => {
          if (response?.data) {
            setDmStores(response?.data);
            setIsDistrictLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching stores:", error);
          setIsDistrictLoading(false);
        });
    } catch (error) {
      console.error("[getDistrictManagerStores]: ", error);
    }
  };

  const getDistrictDashboard = async () => {
    try {
      const response = await districtService.getDistrictDashboard(selectedWeek);
      if (response?.data) {
        setDistrictDashboardData(response?.data);
        setIsDistrictLoading(false);
      }
    } catch (error) {
      console.error("[getDistrictDashboard]: ", error);
      setIsDistrictLoading(false);
    }
  };

  const getDistrictEfficiency = async () => {
    try {
      setIsDistrictLoading(true);
      const response =
        await districtService.getDistrictEfficiency(selectedWeek);
      if (response?.data) {
        setDistrictEffiencies(response?.data);
        setIsDistrictLoading(false);
      }
    } catch (error) {
      console.error("[getDistrictEfficiency]: ", error);
      setIsDistrictLoading(false);
    }
  };

  const getDistrictPlans = async () => {
    try {
      setIsDistrictLoading(true);
      const response = await districtService.getDistrictPlans(selectedWeek);
      if (response?.data) {
        setDistrictPlans(response?.data);
        setIsDistrictLoading(false);
      }
    } catch (error) {
      console.error("[getDistrictPlans]: ", error);
      setIsDistrictLoading(false);
    }
  };

  const getDistrictChartData = async (weeks: number | null = null) => {
    setChartLoading(true);
    try {
      await districtService
        .getDistrictChart(selectedWeek, weeks ?? selectedLastWeek.value)
        .then(async (response) => {
          if (response?.data) {
            const varianceToPlan = {
              data: response?.data?.varianceToPlan,
              lines: response?.data?.varianceToPlan
                ? Object.keys(response?.data?.varianceToPlan[0])?.filter(
                    (k) => k !== "date",
                  )
                : [],
              chartTitle: `Weekly Hours Variance to Plan - Last ${
                weeks ?? selectedLastWeek.value
              } Weeks`,
              chartSubtitle: "Actual Hours % Over/Under Plan",
            };
            setDistrictChartData(varianceToPlan);
            setChartLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching district data:", error);
          setChartLoading(false);
        });
    } catch (error) {
      console.error("[getRegionChartData]: ", error);
      setChartLoading(false);
    }
  };

  const getDistrictKeyAlerts = async () => {
    try {
      setIsDistrictLoading(true);
      const response = await districtService.getKeyAlerts(selectedWeek);
      if (response?.data) {
        setKeyAlerts(response?.data);
        setIsDistrictLoading(false);
      }
    } catch (error) {
      console.error("[getDistrictKeyAlerts]: ", error);
      setIsDistrictLoading(false);
    }
  };

  const handleLastWeekChange = async (val: any) => {
    try {
      setSelectedLastWeek(val);
      await getDistrictChartData(val.value);
    } catch (error) {
      console.error("[handleLastWeekChange] Error: ", error);
    }
  };

  return {
    isDistrictLoading,
    dmStores,
    selectedTab,
    districtDashboardData,
    districtEffiencies,
    districtPlans,
    chartLoading,
    districtChartData,
    keyAlerts,
    selectedLastWeek,
    getSelectedWeek,
    handleTabChange,
    handleLastWeekChange,
  };
};
