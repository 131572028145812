import { useLocalStorage } from "@uidotdev/usehooks";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import { ROUTES } from "../../routes/constants";
import regionService from "../../services/regionService";
import { generateChartData } from "../../utils/helpers";
import { DISTRICT_COLUMN_DEFS } from "./GridColumns/districtColumns";
import { REGION_COLUMN_DEFS } from "./GridColumns/regionColumns";
import { District, Region } from "./RegionFilter";
import { WEEKS_DATA } from "../../utils/constants";

export const useRegion = () => {
  const location = useLocation();
  const regionMatch = useMatch(ROUTES.REGION);
  const regionStoreMatch = useMatch(ROUTES.REGION_STORES);
  const [filterLoading, setFilterLoading] = useState<boolean>(true);
  const [selectedWeek, setSelectedWeek] = useState<string>("");
  const [selectedDistrict, setSelectedDistrict] = useState<District | null>(
    null,
  );
  // eslint-disable-next-line
  const [storedWeek, setStoredWeek] = useLocalStorage("storedWeek", "");
  const [regionHeader, setRegionHeader] = useState<any>({
    title: "Analysis for all Regions",
    subTitle: "Here is your analysis for stores in regions",
  });
  const [dashboardLoading, setDashboardLoading] = useState<boolean>(true);
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const [regions, setRegions] = useState<any[]>([]);
  const [regionDashboardData, setRegionDashboardData] = useState<any>({
    dashboardData: null,
    kpiData: null,
  });
  const [regionChartData, setRegionChartData] = useState<any>({
    target: null,
    plan: null,
    store: null,
  });
  const [gridColumns, setGridColumns] = useState(REGION_COLUMN_DEFS);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [selectedLastWeek, setSelectedLastWeek] = useState<any>(WEEKS_DATA[0]);

  const getRegions = async (term = "") => {
    setFilterLoading(true);
    try {
      await regionService
        .getRegions(term)
        .then(async (response) => {
          if (response?.data) {
            setRegions(response?.data);
            setFilterLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching regions:", error);
          setFilterLoading(false);
        });
    } catch (error) {
      console.error("[getRegions]: ", error);
      setFilterLoading(false);
    }
  };

  const getRegionDashboardData = async (startWeek: string | null = null) => {
    const payload = startWeek || selectedWeek;
    setDashboardLoading(true);
    try {
      await regionService
        .getRegionDashboard(payload)
        .then(async (response) => {
          if (response?.data) {
            setRegionDashboardData({
              dashboardData: response?.data?.regionDashboard,
              kpiData: response?.data?.regionDashboardKPI,
            });
            setDashboardLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching region data:", error);
          setDashboardLoading(false);
        });
    } catch (error) {
      console.error("[getRegionDashboardData]: ", error);
      setDashboardLoading(false);
    }
  };

  const getRegionChartData = async (
    startWeek: string | null = null,
    weeks: number | null = null,
  ) => {
    const payload = startWeek || selectedWeek;
    setChartLoading(true);
    try {
      await regionService
        .getRegionChart(payload, weeks ?? selectedLastWeek.value)
        .then(async (response) => {
          if (response?.data) {
            const tempChartData = generateChartData(response?.data);
            setRegionChartData({
              ...tempChartData,
              target: {
                ...tempChartData.target,
                chartTitle: "Weekly Efficiency Variance to Target by Region",
                chartSubtitle: "Efficiency KPI % Over/Under Target",
              },
              plan: {
                ...tempChartData.plan,
                chartTitle: "Weekly Hours Variance to Plan by Region",
                chartSubtitle: "Actual Hours % Over/Under Plan",
              },
            });
            setChartLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching region chart data:", error);
          setChartLoading(false);
        });
    } catch (error) {
      console.error("[getRegionChartData]: ", error);
      setChartLoading(false);
    }
  };

  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    if (location.state && selectedWeek) {
      if (location.state && location.state.fromDrawer && regionMatch) {
        handleShowAll();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const getSelectedWeek = async (weekRange: any) => {
    if (weekRange) {
      const startWeek = format(weekRange.start, "yyyy-MM-dd");
      setSelectedWeek(startWeek);
      setStoredWeek(JSON.stringify(weekRange));
      setTimeout(async () => {
        if (selectedRegion) {
          await handleRegionChange(selectedRegion, startWeek);
        } else {
          await getRegionChartData(startWeek);
          await getRegionDashboardData(startWeek);
        }
      }, 500);
    }
  };

  const handleSearchRegions = async (term: string) => {
    try {
      if (term === "" || term.length > 1) {
        await getRegions(term);
      }
    } catch (error) {
      console.error("[handleSearchRegions]: ", error);
    }
  };

  const handleRegionChange = async (region: Region, tempWeek = null) => {
    if (!region || !regionMatch) return false;
    setSelectedRegion(region);
    setDashboardLoading(true);
    setChartLoading(true);
    setRegionHeader({
      title: `Analysis for ${region.name || ""}`,
      subTitle: "Here is your analysis for stores in Districts",
    });
    setGridColumns(DISTRICT_COLUMN_DEFS);
    try {
      await regionService
        .getRegionDashboardById(region.regionId, tempWeek ?? selectedWeek)
        .then(async (response) => {
          if (response?.data) {
            setRegionDashboardData({
              dashboardData: response?.data?.districtDashboard,
              kpiData: response?.data?.districtDashboardKPI,
            });
            setDashboardLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching region data:", error);
          setDashboardLoading(false);
        });
      await regionService
        .getRegionChartById(
          region.regionId,
          tempWeek ?? selectedWeek,
          selectedLastWeek.value,
        )
        .then(async (response) => {
          if (response?.data) {
            const tempChartData = generateChartData(response?.data);
            setRegionChartData({
              ...tempChartData,
              target: {
                ...tempChartData.target,
                chartTitle: "Weekly Efficiency Variance to Target by District",
                chartSubtitle: "Efficiency KPI % Over/Under Target",
              },
              plan: {
                ...tempChartData.plan,
                chartTitle: "Weekly Hours Variance to Plan by District",
                chartSubtitle: "Actual Hours % Over/Under Plan",
              },
            });
            setChartLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching region data:", error);
          setChartLoading(false);
        });
    } catch (error) {
      console.error("[handleRegionChange]: ", error);
      setDashboardLoading(false);
      setChartLoading(false);
    }
  };

  const handleShowAll = async () => {
    if (regionStoreMatch || !selectedWeek) return false;
    setDashboardLoading(true);
    setSelectedRegion(null);
    try {
      await getRegions();
      await getRegionChartData();
      await getRegionDashboardData();
      setRegionHeader({
        title: "Analysis for all Regions",
        subTitle: "Here is your analysis for stores in Districts",
      });
      setGridColumns(REGION_COLUMN_DEFS);
    } catch (error) {
      console.error("[handleShowAll]:", error);
    }
  };

  const onDistrictChange = (district: District | null) => {
    setSelectedDistrict(district);
  };

  const handleLastWeekChange = async (val: any) => {
    try {
      setSelectedLastWeek(val);
      if (selectedRegion) {
        setChartLoading(true);
        await regionService
          .getRegionChartById(selectedRegion.regionId, selectedWeek, val.value)
          .then(async (response) => {
            if (response?.data) {
              const tempChartData = generateChartData(response?.data);
              setRegionChartData({
                ...tempChartData,
                target: {
                  ...tempChartData.target,
                  chartTitle:
                    "Weekly Efficiency Variance to Target by District",
                  chartSubtitle: "Efficiency KPI % Over/Under Target",
                },
                plan: {
                  ...tempChartData.plan,
                  chartTitle: "Weekly Hours Variance to Plan by District",
                  chartSubtitle: "Actual Hours % Over/Under Plan",
                },
              });
              setChartLoading(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching region data:", error);
            setChartLoading(false);
          });
      } else {
        await getRegionChartData(null, val.value);
      }
    } catch (error) {
      console.error("[handleLastWeekChange] Error: ", error);
    }
  };

  return {
    filterLoading,
    dashboardLoading,
    chartLoading,
    regions,
    selectedWeek,
    regionDashboardData,
    regionChartData,
    regionHeader,
    gridColumns,
    selectedDistrict,
    selectedLastWeek,
    onDistrictChange,
    getSelectedWeek,
    handleSearchRegions,
    handleRegionChange,
    handleShowAll,
    handleLastWeekChange,
  };
};
